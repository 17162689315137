<template>
  <div class="content">
    <!-- 内容 -->
    <div id="div1" style>
      <!-- 背景图 -->
      <div class="container">
        <div class="box text-center ">
          <div>
            <h1 class="text-5xl font-semibold	">
              亿保云科技是一家互联网保险科技平台
            </h1>
            <h2 class="text-2xl 	mt-10">
              我们以“定制+科技”的战略践行“让保险销售更简单”的公司使命
            </h2>
            <p class="mt-5">
              自2016年成立以来，公司不断推进产品定制化和流程数智化转型升级，先后为超过100家合作伙伴提供高质量的产品定制和科技赋能，
            </p>
            <p class="mt-5">
              为保险销售的数智化提供更多可能选择。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="div3">
      <div class="pic-title">业务介绍</div>
      <div class="pic-container">
        <div class="car-group" data-aos="fade-up">
          <!-- 图片 -->
          <a class="card">
            <div class="card-img-top">
              <div class="card_img">
                <img :src="jbbLogo" />
              </div>
            </div>
            <div class="card-body">
              <div class="card-body-container">
                <!-- <div class="card-title">金保宝平台</div> -->
                <div class="card-text">
                  白鸽宝保险经纪有限公司（下称『白鸽宝』）是以保险科技驱动的全国互联网保险
                  技术与产品解决方案供应商，为客户提供完整的场景化SaaS云平台全流程服务生态
                  。白鸽宝持有全国保险经纪牌照、互联网保险经营销售许可资质及增值电信业务经
                  营许可证，是AAA级信用企业。其母公司白鸽在线（厦门）网络科技有限公司是厦门
                  市三高企业、国家高新技术企业、入驻省级科技型企业库、福建省重点上市后备企业
                  、厦门市重点上市后备企业、厦门市科创板上市后备企业。
                </div>
              </div>
            </div>
          </a>
          <a class="card">
            <div class="card-img-top">
              <div class="card_img">
                <img :src="ebbLogo" />
              </div>
            </div>
            <div class="card-body">
              <div class="card-body-container">
                <!-- <div class="card-title">亿保贝平台</div> -->
                <div class="card-text">
                  善康禾诺保险经纪有限公司于 2015 年 12 月 18 日经中国银保监会批准设立，注册地位
                  于河北，注册资本金5000万人民币，是拥有全国性保险经纪资质及保险网销资格的专业保险
                  经纪公司，也是互联网保险开拓者。目前已在广东、深圳、山东、四川、山西、湖南、安徽、
                  黑龙江等地建立八家省级分公司。公司致力于为客户提供专业化、个性化服务，“成为最受客户
                  信赖的健康顾问及保险服务商” 是公司全体员工的共同愿景。
                </div>
              </div>
            </div>
          </a>
          <a class="card">
            <div class="card-img-top">
              <div class="card_img">
                <img :src="hzbxLogo" />
              </div>
            </div>
            <div class="card-body">
              <div class="card-body-container">
                <!-- <div class="card-title">汇中保险</div> -->
                <div class="card-text">
                  上海汇中保险经纪有限责任公司是经原中国保监会批准设立的全国性专业保险服务机构
                  ，集保险科技、保险经纪、保险公估三大服务平台于一体，致力于做中国领先的O2O互联
                  网保险服务平台。 公司秉承“科技驱动、聚焦寿险、线上线下”的经营理念，以创新B2A2
                  C模式为保险当事人提供专业保险服务，并持续加强风险管控，提升经营效益，以实现“为
                  客户创造价值，为员工创造平台，为股东创造财富”的企业使命。 公司以科技赋能保险的
                  创新精神，在大数据、人工智能、区块链等技术研发领域持续加码，在精准营销、智能投
                  保、智能客服、智能运营、智能理赔等保险科技应用方面不断发力。截止2018年底，公司
                  总计取得21项软件著作权，获得“广州市科技创新小巨人企业”及“上海市高新技术企业”认
                  定。 经过多年发展，公司的专业服务品牌获得市场及行业广泛认可，先后荣获“创业在上
                  海”国际创新创业大赛优胜奖、保险中介行业“诚信服务示范单位”、“中国保险公估业十大
                  品牌企业”、中国质量万里行服务“优（A）”单位等称号。
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="div4" class="mt-20">
      <div class="pic-title">联系我们</div>
      <div class="pic-container">
        <div class="flex w-full gap-28">
          <div class="w-3/5">
            <baidu-map
              class="w-full h-96"
              :center="center"
              :zoom="zoom"
              @ready="handler"
              enableScrollWheelZoom
            >
              <bm-marker
                :position="center"
                @click="infoWindowOpen(item)"
                :icon="{ url: mapPoint, size: { width: 50, height: 50 } }"
              >
              </bm-marker>
              <!-- 信息窗口 -->
              <bm-info-window
                :position="center"
                :show="infoWindow.show"
                @close="infoWindowClose"
                @open="infoWindowOpen"
              >
                <div class="info-window">
                  <h1 class="font-bold">详细地址</h1>
                  <p class="mt-3" style="color: #706f6e;">
                    无锡市滨湖区楝泽路28号软件园B区
                  </p>
                </div>
              </bm-info-window>
            </baidu-map>
          </div>
          <div class="w-2/5 text-base	" style="color: #706f6e;">
            <el-row :gutter="20" class="h-8">
              <el-col :span="10">
                <div class="grid-content bg-purple">商务部</div>
              </el-col>
              <el-col :span="14">
                <div class="grid-content bg-purple">高先生 13806191685</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="h-8">
              <el-col :span="10">
                <div class="grid-content bg-purple">技术部</div>
              </el-col>
              <el-col :span="14">
                <div class="grid-content bg-purple">张先生 18651618822</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="h-8">
              <el-col :span="10">
                <div class="grid-content bg-purple">市场部</div>
              </el-col>
              <el-col :span="14">
                <div class="grid-content bg-purple">闵先生 13914266435</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="h-8">
              <el-col :span="10">
                <div class="grid-content bg-purple">网站运营负责人</div>
              </el-col>
              <el-col :span="14">
                <div class="grid-content bg-purple">陈先生 13382223510</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="h-8">
              <el-col :span="10">
                <div class="grid-content bg-purple">联系邮箱</div>
              </el-col>
              <el-col :span="14">
                <div class="grid-content bg-purple">
                  bangyibao01@bebao99.com
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"], //此方法刷新页面 无闪跳
  data() {
    return {
      imageProxy: require("@/assets/green.jpg"),
      jbbLogo: require("@/assets/jbbLogo.jpg"),
      ebbLogo: require("@/assets/ebb.jpg"),
      hzbxLogo: require("@/assets/hzbx.jpg"),
      mapPoint: require("@/assets/mapPoint.png"),
      form: {
        username: "",
        password: ""
      },
      center: { lng: 0, lat: 0 },
      zoom: 0,
      infoWindow: {
        show: false
      }
    };
  },
  computed: {},
  mounted() {
    // 滚动条的获取(注册)
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    // 页面销毁 注销事件
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handler({ BMap, map }) {
      //坐标中心（无锡）
      this.center.lng = 120.277566;
      this.center.lat = 31.473838;
      this.zoom = 10;
      //允许鼠标滑动 缩放地图
      map.enableScrollWheelZoom(true);
    },
    infoWindowClose() {
      this.infoWindow.show = false;
    },
    infoWindowOpen() {
      this.infoWindow.show = true;
    }
  }
};
</script>

<style scoped>
.pic-container {
  width: 1059px;
  margin: 0 auto;
  display: flex;
}

.pic-title {
  width: 1050px;
  margin: 0 auto;
  height: 80px;
  line-height: 80px;
  font-size: 28px;
  color: black;
  font-weight: 400;
}
.car-group {
  height: 468.2px;
  width: 100%;
}

.card:hover img {
  transform: scale(1.08);
}

.card-img-top {
  width: 60%;
  height: 100%;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  margin: auto;
}

.card_img {
  height: 100%;
  background-size: cover;
  transform: scale(1, 1);
  overflow: hidden;
}

.card_img img {
  cursor: pointer;
  /*过渡效果  <property> <duration> <>*/
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.card {
  height: (100%-100px);
  width: 100%;
  flex-direction: row;
  display: flex;
}

.card-body {
  background-color: rgb(255, 255, 255);
  padding: 0px 50px 0px 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body-container {
  width: 780px;
}

.card-text {
  font-size: 16px;
  color: #706f6e;
  letter-spacing: 0.01em;
  line-height: 1.4em;
  margin: 20px 0px 25px 0px;
  text-align: left;
}

.container {
  height: 550px;
  background: url(../../assets/green.jpg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-width: 100%;
}

.box {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  color: rgba(17, 25, 37, 0.85);
}

.box::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 35%;
}
</style>
